import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import ProjectsOverview from '../components/projectsOverview'

const CasePage = () => (
  <Layout>
    <SEO
      title="Case Studies"
      keywords={[
        `Venture Architect`,
        `MVP Development`,
        `User Experience Designer`,
        `UX`,
        `UI`,
        `Interactive Design`,
        `Interactive Media Design`,
        `Design Thinking Darmstadt`,
        `Danny Giebe`,
        `Front-end Developer`,
        `Interdisciplinary Designer`,
        `Design Consulting`,
        `Design Thinking Workshop Darmstadt`,
        `IoT Designer`,
      ]}
    />

    <h1>Case Studies</h1>

    <ProjectsOverview />

    <div className="cta-section">
      <section>
        <h2>Need help with your project?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to get a{' '}
          <strong>great user experience and design</strong>. <br /> I would be
          happy to help you, making your product experience exceptionally.{' '}
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Get in touch
        </a>
      </section>
    </div>
  </Layout>
)

export default CasePage
